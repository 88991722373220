<template>
  <div class="hae-home">
    <img
      alt="checker logo"
      src="../../assets/hae/top-ttl.svg"
      class="title-logo"
    />
    <div class="white-box">
      <img alt="checker logo" src="../../assets/hae/home_check_logo.svg" />
      <p class="heading-text">
        はじめに
      </p>
      <p class="bold-text">
        くり返す腫れ・むくみ、痛みの症状をその場でセルフチェックできます。
      </p>
      <ul class="dot-list">
        <li>最大17項目あり、2分程度で完了できます。</li>
        <li>腫れ・むくみの原因を簡易的にチェックできます。</li>
        <li>正しい診断を受けるためには必ず医師の診察を受けてください。</li>
      </ul>
      <div v-if="isLogined">
        <router-link
          :to="{ name: 'HaeFirstScreening' }"
          class="lineless"
          @click.native="sendGa('click', 'hae', 'start-screening')"
        >
          <CommonButton text="診断スタート" styleType="active"
        /></router-link>
      </div>
      <div v-else>
        <a
          @click="
            isLoginModalActive = true;
            sendGa('click', 'hae', 'start-screening-without-login');
          "
        >
          <CommonButton text="診断スタート" styleType="active" />
        </a>
      </div>
    </div>
    <br />
    <QuoteOrigin />
    <br />
    <b-modal v-model="isLoginModalActive" :width="450" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p class="login-text">
              AskDoctorsの無料会員登録<br />またはログインが必要です。
            </p>
            <a
              v-bind:href="fetchLoginUrl"
              @click="sendGa('click', 'hae', 'login')"
            >
              <CommonButton text="会員の方はログイン" styleType="active" />
            </a>
            <b-field>
              <b-switch v-model="isSendAskDoctorsEmail" size="is-small"
                >AskDoctorsお役立ちメルマガを受け取る</b-switch
              >
            </b-field>
            <p class="social-dialog-txt">
              <small
                ><a
                  href="https://www.askdoctors.jp/open/terms/"
                  target="_blank"
                  class="with-underline"
                  >利用規約</a
                >と<a
                  href="http://corporate.m3.com/privacy/"
                  target="_blank"
                  class="with-underline"
                  >個人情報の取り扱い</a
                >に<br />
                同意のうえ、会員登録/ログインをしてください</small
              >
            </p>
            <div class="social-btn">
              <div class="google-btn">
                <a
                  @click="sendGa('click', 'hae', 'google-register')"
                  class="js-social-login-url"
                  :href="`${fetchOpenIdLoginUrl}?${fetchUrlParams('google')}`"
                >
                  <p class="google-btn-logo">
                    <img src="../../assets/hae/logo_google.svg" />
                  </p>
                  <p class="google-btn-txt">Googleアカウントで続ける</p>
                </a>
              </div>
              <div class="yahoo-btn">
                <a
                  class="js-social-login-url"
                  @click="sendGa('click', 'hae', 'yahoo-register')"
                  :href="`${fetchOpenIdLoginUrl}?${fetchUrlParams('yahoojp')}`"
                >
                  <p class="yahoo-btn-logo">
                    <img src="../../assets/hae/logo_yahoo.png" />
                  </p>
                  <p class="yahoo-btn-txt">Yahoo! JAPAN IDで続ける</p>
                </a>
              </div>
              <div class="line-btn">
                <a
                  class="js-social-login-url"
                  @click="sendGa('click', 'hae', 'line-register')"
                  :href="`${fetchOpenIdLoginUrl}?${fetchUrlParams('line')}`"
                >
                  <p class="line-btn-logo">
                    <img src="../../assets/hae/logo_line.png" />
                  </p>
                  <p class="line-btn-txt">LINEで続ける</p>
                </a>
              </div>
              <div class="apple-btn">
                <a
                  class="js-social-login-url"
                  @click="sendGa('click', 'hae', 'apple-register')"
                  :href="`${fetchOpenIdLoginUrl}?${fetchUrlParams('apple')}`"
                >
                  <p class="apple-btn-logo">
                    <img src="../../assets/hae/logo_apple.png" />
                  </p>
                  <p class="apple-btn-txt">Appleでサインアップ</p>
                </a>
              </div>
              <p><small>※個人のSNSに利用状況は投稿されません</small></p>
              <p class="push-top--sm push-bottom--sm">または</p>
              <div class="qa-comment-mask-btn push-bottom">
                <p>
                  <a
                    class="email-btn button button--tertiary button--md"
                    v-bind:href="fetchFreeRegisterUrl"
                    @click="sendGa('click', 'hae', 'free-register')"
                    >メールアドレスで登録</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import rootStore from "@/store";
import CommonButton from "@/components/hae/CommonButton.vue";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
const state = rootStore.state;
const redirectUrl = encodeURIComponent(
  process.env.VUE_APP_BASE_URL + "hae/first_screening"
);
export default {
  name: "HaeHome",
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  data() {
    return {
      isLoginModalActive: false,
      isSendAskDoctorsEmail: true
    };
  },

  created() {
    rootStore.commit("hae/resetState");
  },
  computed: {
    isLogined() {
      return (
        state.hae.userStatus === "charged" || state.hae.userStatus === "free"
      );
    },
    fetchFreeRegisterUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}user/pre-register/free?${this.fetchUrlParams('')}`;
    },
    fetchLoginUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}login?return_to=${redirectUrl}`;
    },
    fetchOpenIdLoginUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}user/free_registration_session/new`;
    }
  },
  methods: {
    fetchUrlParams(type) {
      const emailParam = this.isSendAskDoctorsEmail ? 1 : 0;
      const url = `no_confirm=true&send_ask_doctors_email=${emailParam}&return_to=${redirectUrl}&immediately_return=true`

      return type ? `${url}&provider=${type}` : url
    }
  },
  components: { CommonButton, QuoteOrigin },
  beforeCreate: function() {
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info", {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        this.$store.commit("hae/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
      });
  }
};
</script>

<style scoped>
@import "../../assets/style/hae.css";
.title-logo {
  margin-top: 60px;
  margin-bottom: 20px;
}

.text-left {
  text-align: left;
}

.circle {
  float: left;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-align: center;
  background: #ff9900;
  line-height: 44px;
  font-weight: bold;
  font-size: 24px;
  color: #fefefe;
}
.circle-title {
  float: left;
  font-weight: bold;
  font-size: 20px;
  width: 200px;
  color: #111111;
  margin-top: 0px;
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.flow-box {
  overflow: hidden;
  margin-top: -20px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-box {
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 20px;
}

.google-btn-logo {
  display: inline-block;
  border-radius: 8px 0 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 62px;
}

@media (min-width: 1032px) {
  .google-btn-logo {
    border-radius: 4px 0 0 4px;
  }
}
.line-btn-logo,
.yahoo-btn-logo,
.apple-btn-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 40px;
}

.line-btn a,
.yahoo-btn a,
.google-btn a,
.apple-btn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}

a {
  color: #324646;
}

.with-underline {
  text-decoration: underline;
}

.google-btn {
  border: 1px solid #dce1e1;
  background: #fff;
  border-radius: 6px;
}

.line-btn,
.yahoo-btn,
.google-btn,
.apple-btn {
  margin: 0 auto 10px;
  height: 44px;
  width: 100% !important;
  position: relative;
}

.line-btn-txt,
.yahoo-btn-txt,
.apple-btn-txt {
  color: #fff;
}

.line-btn-txt,
.yahoo-btn-txt,
.google-btn-txt,
.apple-btn-txt {
  width: 100%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  font-size: 0.875rem;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.yahoo-btn-logo {
  width: 62px;
  height: 40px;
  background: #fff;
  border-radius: 8px 0 0 8px;
  height: 40px;
  border-radius: 4px 0 0 4px;
}

.login-text {
  color: #ff8900;
  font-size: 20px;
  font-weight: bold;
}

.yahoo-btn {
  border: 2px solid #ff0033;
  background: #ff0033;
  border-radius: 6px;
}

.google-btn-logo img {
  height: 44px;
  border-radius: 8px;
}

.yahoo-btn-logo img {
  width: 32px;
}

.line-btn {
  border: none;
  background: #00c300;
  border-radius: 8px;
}
.line-btn-logo {
  width: 64px;
  border-right: 1px solid #00b300;
}
.line-btn-logo img {
  width: 40px;
}

.line-btn-logo img,
.yahoo-btn-logo img,
.google-btn-logo img,
.apple-btn-logo img {
  display: block;
  flex-shrink: 0;
}

.apple-btn {
  background: #000;
  border-radius: 6px;
}

.apple-btn-logo {
  width: 64px;
  border-radius: 8px 0 0 8px;
}

.apple-btn-logo img {
  height: 44px;
}

.apple-btn-txt,
.yahoo-btn-txt,
.line-btn-txt {
  font-weight: 700;
}

.email-btn {
  padding: 8px;
  width: 100% !important;
  font-size: 0.875rem;
}
</style>
